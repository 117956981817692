import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../Modal';
import { ButtonPrimary, ButtonSecondary } from '../Button';
import Logo from '../../assets/logos/WH1.svg';
import CloseX from '../../assets/images/x.svg';
import { isMobile } from 'react-device-detect';

interface ApplicationFeesModalProps {
  isOpen: boolean;
  onDismiss: () => void;
}

const CloseButton = styled.img`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: none;
  padding: ${isMobile ? '2rem 1.5rem' : '3.5rem'};
  gap: 1rem;
  width: 100%;
  font-size: 1rem;
  color: #393a40;
`;

const Icon = styled.img`
  width: 3.5rem;
  height: 3.5rem;
`;

const Title = styled.h4`
  font-weight: bold;
  margin: 0;
  line-height: 1.5;
`;

const Description = styled.p`
  margin: 0;
  line-height: 1.5;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1.5rem 0.5rem;

  button {
    border-color: #0054d8;
    min-width: fit-content;
    border-radius: 50px;
    padding: ${isMobile ? '0.5rem 1rem' : '0.75rem 1.5rem'};
    outline: none;
    font-size: ${isMobile ? '0.75rem' : '0.875rem'};
    font-weight: 700;

    &:hover {
      border-color: #0054d8;
      box-shadow: 0 4px 8px rgba(0, 84, 216, 0.2);
      outline: none;
    }

    &:focus {
      box-shadow: none;
      background-color: none;
      border-color: #0054d8;
    }
  }
`;

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;

  span {
    font-size: 0.875rem;
  }
`;

const Checkbox = styled.input.attrs((props) => ({
  type: 'checkbox',
  checked: props.checked,
}))`
  margin-right: 8px;
  cursor: pointer;
`;

const ApplicationFeesModal: React.FC<ApplicationFeesModalProps> = ({ isOpen, onDismiss }) => {
  const [doNotShowAgain, setDoNotShowAgain] = useState(true);

  useEffect(() => {
    const savedValue = localStorage.getItem('doNotShowAgain');
    if (savedValue) {
      setDoNotShowAgain(JSON.parse(savedValue));
    }
  }, []);

  const handleDismissClick = () => {
    // We are not using doNotShowAgain state because if we do, clicking on the checkbox will immediately close the modal
    const checkboxChecked = document.getElementById('doNotShowAgainCheckbox') as HTMLInputElement;

    if (checkboxChecked?.checked) {
      localStorage.setItem('doNotShowAgain', JSON.stringify(true));
    }

    onDismiss();
  };

  const handleLearnMore = () => {
    window.open('https://docs.haven1.org/build/application-fees', '_blank', 'noopener noreferrer');
  };

  if (doNotShowAgain) return null;

  return (
    <Modal isOpen={isOpen} onDismiss={handleDismissClick} maxWidth={587}>
      <ModalContent>
        <CloseButton src={CloseX} alt="Close Button" onClick={onDismiss} />
        <Icon src={Logo} alt="Haven1 logo" />
        <Title>Welcome!</Title>
        <Description>
          We are aware the current UI is a bit buggy. We are in the process of moving this Uniswap v2 fork to a Uniswap
          v3 fork, enhancing features and UI for a superior user experience.
        </Description>
        <Description>
          H1 is required to pay the native application fee. Please visit the faucet to collect your H1.
        </Description>
        <ButtonsContainer>
          <ButtonSecondary onClick={handleDismissClick}>Dismiss</ButtonSecondary>
          <ButtonPrimary onClick={handleLearnMore}>Learn More</ButtonPrimary>
        </ButtonsContainer>
        <CheckboxContainer>
          <Checkbox id="doNotShowAgainCheckbox" type="checkbox" />

          <span>Do not show this again</span>
        </CheckboxContainer>
      </ModalContent>
    </Modal>
  );
};

export default ApplicationFeesModal;
