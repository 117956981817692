import { HAVEN_CONFIG } from 'constants/havenConfig';
import React, { useContext } from 'react';
import { Text } from 'rebass';
import { ThemeContext } from 'styled-components';

export default function ApplicationFeeLink(): JSX.Element {
  const theme = useContext(ThemeContext);
  return (
    <Text fontWeight={500} fontSize={14} color={theme.text2}>
      <a href={HAVEN_CONFIG.feeLearnMore} rel="noopener noreferrer" target="_blank">
        Learn more
      </a>{' '}
      about Haven1&apos;s Native Application Fee.
    </Text>
  );
}
