import React, { useMemo } from 'react';
import { Currency, ETHER, Token } from '@uniswap/sdk';
import styled from 'styled-components';
import useHttpLocations from '../../hooks/useHttpLocations';
import { WrappedTokenInfo } from '../../state/lists/hooks';
import Logo from '../Logo';
import * as Logos from '../../assets/logos/logos';

const logoMap: { [key: string]: string } = {
  hBTC: Logos.hBTC,
  hETH: Logos.hETH,
  hLINK: Logos.hLINK,
  hUSDC: Logos.hUSDC,
  WH1: Logos.WH1,
  SILVER: Logos.SILVER,
  GOLD: Logos.GOLD,
};

const getTokenLogoURL = (name: string) => {
  return logoMap[name];
};

interface CurrencyLogoProps {
  currency?: Currency;
  size?: string;
  style?: React.CSSProperties;
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`;

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`;

const CurrencyLogo: React.FC<CurrencyLogoProps> = ({ currency, size = '24px', style }) => {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : logoMap.WH1);

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return [];

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.symbol as string)];
      }
      return [getTokenLogoURL(currency.symbol as string)];
    }
    return [];
  }, [currency, uriLocations]);

  if (currency === ETHER) {
    return <StyledEthereumLogo src={logoMap.WH1} size={size} style={style} />;
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />;
};

export default CurrencyLogo;
