/* eslint-disable */
import { ChainId, JSBI, Percent, Token, WETH } from '@uniswap/sdk';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { injected } from '../connectors';
import { HAVEN_CONFIG } from './havenConfig';

export const FACTORY_ADDRESS = HAVEN_CONFIG.factoryAddress;
export const ROUTER_ADDRESS = HAVEN_CONFIG.routerAddress;

export const LP_TOKEN_NAME = HAVEN_CONFIG.lpTokenName;
export const LP_TOKEN_SYMBOL = HAVEN_CONFIG.lpTokenSymbol;

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[];
};

export const WH1_ADDRESS = process.env.REACT_APP_WH1_ADDRESS as string;
export const HBTC_ADDRESS = process.env.REACT_APP_HBTC_ADDRESS as string;
export const HETH_ADDRESS = process.env.REACT_APP_HETH_ADDRESS as string;
export const HUSDC_ADDRESS = process.env.REACT_APP_HUSDC_ADDRESS as string;
export const HLINK_ADDRESS = process.env.REACT_APP_HLINK_ADDRESS as string;

export const WH1 = new Token(ChainId.HAVEN1, WH1_ADDRESS, 18, 'WH1', 'Wrapped H1');
export const HBTC = new Token(ChainId.HAVEN1, HBTC_ADDRESS, 8, 'hBTC', 'Haven1 Bitcoin');
export const HETH = new Token(ChainId.HAVEN1, HETH_ADDRESS, 18, 'hETH', 'Haven1 Ethereum');
export const HUSDC = new Token(ChainId.HAVEN1, HUSDC_ADDRESS, 6, 'hUSDC', 'Haven1 USD Coin');
export const HLINK = new Token(ChainId.HAVEN1, HLINK_ADDRESS, 18, 'hLINK', 'Haven1 Chainlink');

const WETH_ONLY: ChainTokenList = {
  [ChainId.HAVEN1]: [WETH[ChainId.HAVEN1]],
};

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.HAVEN1]: [...WETH_ONLY[ChainId.HAVEN1], WH1, HBTC, HETH, HUSDC, HLINK],
};

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {};

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.HAVEN1]: [...WETH_ONLY[ChainId.HAVEN1], HBTC, HETH, HUSDC, HLINK],
};

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.HAVEN1]: [...WETH_ONLY[ChainId.HAVEN1], HBTC, HETH, HUSDC, HLINK],
};

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.HAVEN1]: [
    [WH1, HETH],
    [WH1, HBTC],
    [WH1, HUSDC],
  ],
};

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
    mobile: true,
  },
  // WALLET_CONNECT: {
  //   connector: walletconnect,
  //   name: 'WalletConnect',
  //   iconName: 'walletConnectIcon.svg',
  //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //   href: null,
  //   color: '#4196FC',
  //   mobile: true,
  // },
  // WALLET_LINK: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   color: '#315CF5',
  // },
};

export const NetworkContextName = 'NETWORK';
// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;
// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7);
export const BIG_INT_ZERO = JSBI.BigInt(0);
// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));
export const BIPS_BASE = JSBI.BigInt(10000);
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE); // 15%
// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)); // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000));
export const ZERO_PERCENT = new Percent('0');
export const ONE_HUNDRED_PERCENT = new Percent('1');
