/* IMPORT NODE MODULES
================================================== */
import { ethers, BigNumberish } from 'ethers';

/* IMPORT CONSTANTS
================================================== */
import { HAVEN_CONFIG } from '../constants/havenConfig';

/* CONTRACT AND PROVIDER
================================================== */
const { rpc, id } = HAVEN_CONFIG;
const provider = new ethers.providers.JsonRpcProvider(rpc, id);

/* GET HAVEN BAL
================================================== */
export default async function getHavenBal(address: string): Promise<string> {
  const bal: BigNumberish = await provider.getBalance(address);
  return bal.toString();
}
