import React from 'react';
import styled from 'styled-components';
import { RowBetween } from '../Row';
import { TYPE } from '../../theme';
import { ButtonPrimary, ButtonSecondary } from 'components/Button';
import { isMobile } from 'react-device-detect';

const StyledSwapBanner = styled.div`
  padding: 12px 18px;
  width: 100%;
  color: ${({ theme }) => theme.text2};
`;

export const ButtonPrimaryEdited = styled(ButtonPrimary)`
  width: auto;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-wrap: nowrap;
  padding: 8px 20px;
`;

export const CustomLink = styled.a`
  width: auto;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-wrap: nowrap;
  padding: 8px 20px;
  border-radius: 20px;
  color: #0054d8;
  text-decoration: none;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

export default function SwapBanner() {
  return (
    <StyledSwapBanner>
      <RowBetween>
        <TYPE.black fontWeight={700} fontSize="16px" lineHeight={'24px'}>
          Check out Haven Swap V2
        </TYPE.black>
      </RowBetween>
      <RowBetween>
        <TYPE.black fontWeight={400} fontSize="14px" lineHeight={'24px'}>
          Haven Swap V2 is the new, more efficient DEX on Haven1 for traders and LPs. If you’re an LP on V1 Swap,
          migrate now before V1 is deprecated.{' '}
        </TYPE.black>
      </RowBetween>
      <ButtonsContainer>
        <a href="https://swap.testnet.haven1.org">
          <ButtonPrimaryEdited onClick={() => window}>
            {!isMobile ? 'Go to HavenSwap V2' : 'HavenSwap V2'}
          </ButtonPrimaryEdited>
        </a>
        <CustomLink href="https://docs.haven1.org/haven1-testnet-guide/haven-swap-v2" target="_blank">
          Learn more
        </CustomLink>
      </ButtonsContainer>
    </StyledSwapBanner>
  );
}
